import React from "react"
import { Link } from "gatsby"
import "./footer.css"


const Footer = () => {

  return (
    <div className="footer fullwidth-container">
      <p className="footer_info">
        delegálás, feladatkiadás felsőfokon  
      </p>
      <p className="footer_links">
        <Link to={'/bemutato'} >
          Tulajdonságok, funkciók
        </Link>
        -
        <Link to={'/aszf'} >
          ÁSZF
        </Link>
        -
        <Link to={'/adatkezeles'}>
          Adatkezelés
        </Link>
      </p>
    </div>
  )
}

export default Footer
