import React from "react"
import Layout from "../components/layout"
import Provider from "../components/provider"
import "./aszf.css"

const TOSPage = () => (
  <Layout>
    <div className="tos_container sized-container">
      <h1>Általános Szerződési Feltételek (ÁSZF)</h1>
      <h2>1. Értelmező rendelkezések</h2>
      <p>
        A Hicomm Üzleti Kommunikációs Kft. mint a Feladatkiadás 
        (<a href="www.feladatkiadas.hu" target="_blank">www.feladatkiadas.hu</a>) 
        rendszer üzemeltetője (továbbiakban <b>Szolgáltató</b>), valamint a Szolgáltató által 
        üzemeltetett rendszert igénybe vevő felhasználó (továbbiakban <b>Felhasználó</b>) közötti 
        együttműködés tartalmát, a felek jogait és kötelezettségeit az alábbi általános szerződési feltételek tartalmazzák.
      </p>
      <h3>1.1 Szolgáltató adatai</h3>
      <Provider />
      <h3>1.2 Felhasználó</h3>
      <p>
        Az a természetes vagy jogi személy, aki a rendszerbe regisztrál, és így a Szolgáltató 
        a <a href="www.feladatkiadas.hu" target="_blank">www.feladatkiadas.hu</a> rendszer 
        felhasználójaként nyilvántart.
      </p>
      <h3>1.3 A szolgáltatás leírása</h3>
      <p>
        A Szolgáltató egy olyan internet alapú rendszert nyújt a felhasználó számára 
        (továbbiakba <b>Rendszer</b>, melynek segítségével menedzselheti a vállalkozásának, 
        szervezetének feladatkiadásait és az ehhez kapcsolódó feladatait. Feladatokat delegálhat a 
        munkatársainak, partnereinek. A rendszer támogatja és megkönnyíti a feladatkiadó és a feladat 
        végrehajtó közötti napi munkát. A Feladatkiadás rendszer gyors hozzáférést nyújt a személyes és 
        céges kiadott és vagy kapott feladatokhoz, a rendszerbe felvitt információkhoz. A Felhasználó 
        által felvett feladatokat kizárólag a Felhasználó és a Felhasználó által kiválasztott (delegált) 
        másik Felhasználó láthatja, módosíthatja.
      </p>
      <h3>1.4 Szerződéskötés</h3>
      <p>
        A regisztráció befejezése előtt el kell olvasni és el kell fogadni jelen szerződési
        feltételeket. Regisztráció csak abban az esetben történik meg, amennyiben kattintással a 
        Felhasználó bejelöli, hogy elolvasta és elfogadta a szerződést. A Felhasználó tudomásul veszi, 
        hogy ez a fajta ráutaló magatartás, azaz a szerződés elfogadása szerződéskötésnek minősül.
      </p>
      <h3>1.5 Szerződés hatálya</h3>
      <p>
        A szerződés határozatlan időre szól és az előfizetési időszak lejártával automatikusan meghosszabbításra kerül. 
        A Felhasználói Szerződés hatálya a szerződéskötés pillanatától, annak bármelyik fél által történő felmondásáig tart.
      </p>
      <p>
        A Felhasználói Szerződés az előfizetési időszak lejárta után - a felek eltérő írásbeli rendelkezése hiányában -
         folyamatosan hatályban marad (automatikus meghosszabbítás). Az új előfizetési időszakra az aktuális árak, 
         valamint a korábban meghatározott egyéb feltételek az irányadók.
      </p>
      <p>
        A szolgáltatás korlátozottan a szerződéskötés pillanatától használható, teljes mértékben azonban csak az 
        előfizetési díj pénzügyi teljesítését követően vehető igénybe.
      </p>
      <h3>1.6 Szerződés felmondása</h3>
      <p>
        1.6.1 A szerződést a Felhasználó abban az esetben mondhatja fel, ha nem áll fenn tartozási kötelezettsége a 
        Szolgáltató felé. Ebben az esetben írásban, indokolás nélkül kérheti a Szolgáltatás megszüntetését 
        1 hónapos felmondási idővel.
      </p>
      <p>
        1.6.2 A Szolgáltató 1 hónapos felmondási idővel, indokolás nélkül felmondhat a Felhasználónak, 
        abban az esetben, ha visszafizeti az előfizetési díj azon részét, melyre nem nyújt szolgáltatást.
      </p>
      <p>
        1.6.3 Szerződésszegés esetén bármelyik fél azonnali hatállyal felmondhatja a szolgáltatást.
      </p>
      <h2>2. A szerződés tárgya</h2>
      <h3>2.1 A Szolgáltatás </h3>
      <p>
        2.1.1 A Szolgáltató az előfizetés időtartama alatt a Felhasználói Szerződésben meghatározott, 
        az 1.3 pontban körülírt alapszolgáltatást biztosítja a Felhasználónak.
      </p>
      <p>
        2.1.2 A Szolgáltató folyamatosan fejleszti az alaprendszert.
      </p>
      <p>
        2.1.3 A Szolgáltató az alaprendszeren kívül további extra szolgáltatásokat is kifejleszthet, 
        illetve harmadik fél szolgáltatását közvetítheti, melyek nem feltétlenül részei az 
        alapszolgáltatásnak. Ezen szolgáltatások egy része az alap tagdíjért, más részük külön 
        díjért vehető igénybe. Jelen szerződésnek nem részei a külön díjért igénybe vehető 
        extra szolgáltatások, így azokra egyedi szerződések érvényesek.
      </p>
      <p>
        Az alapszolgáltatások a további fejlesztések után is kizárólag a tagdíj befizetésével 
        igénybe vehetők, ezek a fejlesztések csak bővíthetik az alapszolgáltatások számát, 
        és funkcionalitásukat.
      </p>
      <h2>3. Díj és díjfizetés</h2>
      <h3>3.1 A szolgáltatás ellenértéke</h3>
      <p>
        Az alapszolgáltatást a Felhasználó díjmentesen veheti igénybe. Az alapszolgáltatás mennyiségi 
        és funkcionális korlátozásokat tartalmaz az ellenérték fejében nyújtott szolgáltatásokhoz képest.
      </p>
      <p>
        Az ellenérték fejében nyújtott csomagokat a Felhasználó előfizetési díj ellenében veheti igénybe, 
        melyről a Szolgáltató átutalásos számlát állít ki a Felhasználó felé. Az előfizetési időszakra 
        érvényes díjat előre, egy összegben, legkésőbb a számla keltétől számított 8 napon belül köteles a Felhasználó megfizetni.
      </p>
      <h3>3.2 Szolgáltatás díja</h3>
      <p>
        A Szolgáltatás díja a rendeléskor érvényes, a honlapon közzétett árlista szerint alakul. A díjat a 
        választott fizetési periódusnak megfelelően kell kiegyenlíteni. A Szolgáltató jogosult minden év 
        január 1-jével a előfizetési díját maximálisan a tárgyévet megelőző év december 1-jén érvényben 
        lévő jegybanki alapkamat mértékével emelni. Ezt meghaladó díjváltozás csak a szolgáltatás 
        általános bővítésével egyidejűleg állapítható meg.
      </p>
      <h3>3.3 Fizetési kötelezettség</h3>
      <p>
        Ha a Felhasználó a számlában szereplő díjat, az ott jelzett fizetési határidő lejáratát követő 8 napon 
        belül nem egyenlíti ki, vagy nem igazolja, hogy az összeg átutalásához szükséges valamennyi intézkedést 
        megtette, a Szolgáltató írásban felszólítja a teljesítésre. Ha a felszólítás nem jár eredménnyel, 
        a Felhasználói hozzáférést a Szolgáltató azonnali hatállyal - további jognyilatkozat nélkül - letilthatja. 
        Eredménytelen felszólítás esetén a Szolgáltató jogosult a Felhasználót a szolgáltatásból kizárni, 
        vagyis hozzáférését letiltani, adatait átmenetileg elérhetetlenné tenni. Késedelmes fizetés esetén 
        Szolgáltató Ptk. szerinti késedelmi kamatot számít fel.
      </p>
      <p>
        A Felhasználói Szerződés automatikus meghosszabbításakor a Szolgáltató az új előfizetési 
        időszakra vonatkozó szolgáltatói számlát állít ki. Ha az új előfizetési időszakra vonatkozó szolgáltatói
         számlát a Felhasználó pénzügyileg nem teljesíti, a Szolgáltató az így keletkező tartozást kintlévőségként kezeli.
      </p>
      <h3>3.4 Pénz-visszafizetési garancia</h3>
      <p>
        A szolgáltatásra 100 napos pénzvisszafizetési garanciát vállal a Szolgáltató. A Felhasználónak lehetősége 
        van a regisztrációt követő 30 napon belül indokolás nélkül visszamondani a tagságot. Ez esetben a 
        Szolgáltató a Felhasználó által befizetett teljes összeget visszautalja, és minden felvett adatát 
        törli a rendszerből. A pénz-visszafizetési kérelmet írásban lehet benyújtani.
      </p>
      <h2>4. A Szolgáltató jogai és kötelezettségei</h2>
      <p>
        4.1 A Szolgáltató az előfizetés fennállása alatt köteles a Felhasználó számára a Feladatkiadás 
        rendszer használatának lehetőségét folyamatosan biztosítani.
      </p>
      <p>
        4.2 A rendszer használatához szükséges leírást a Szolgáltató közzéteszi 
        a <a href="http://www.feladatkiadas.hu" title="www.feladatkiadas.hu">www.feladatkiadas.hu</a> honlapon, 
        az esetlegesen felmerülő problémákat e-mailben orvosolja.
      </p>
      <p>
        4.3 A Szolgáltató a rendszer üzemelésére 99% rendelkezésre állást vállal. Amennyiben a 
        rendszer használatára a Felhasználónak az előfizetés ideje alatt a Szolgáltató 
        érdekkörében felmerült okból az előfizetés tartamának 1%-át meghaladó időtartamban 
        nem volt módja - ide nem értve, ha a szolgáltatás elháríthatatlan külső okból 
        kifolyólag szünetel - a Felhasználó a már megfizetett előfizetési díj arányos
         díjcsökkentésére tarthat igényt.
      </p>
      <p>
        4.4 A Szolgáltató a Felhasználótól kapott valamennyi információt kizárólag a rendszer 
        működtetése érdekében jogosult felhasználni. Ettől eltérni csak a felhasználó előzetes 
        engedélyével lehet. Az adatvédelemmel és adatkezeléssel kapcsolatos további részletek 
        az 'Adatvédelmi Szabályzatban találhatók.
      </p>
      <p>
        4.5 A Szolgáltató jogosult a részére a Felhasználó által megadott alap - az azonosításhoz szükséges - adatokat ellenőrizni.
      </p>
      <h2>5. A Felhasználó jogai és kötelezettségei</h2>
      <p>
      5.1 A Felhasználó jogosult az előfizetés ideje alatt a Feladatkiadás rendszerben felvitt saját adatait, 
      információit tetszőleges időben és tetszőleges számban módosítani.
      </p>
      <p>
        5.2 A Felhasználó jogosult a Rendszer minden alap szolgáltatását igénybe venni.
      </p>
      <p>
        5.3 A Felhasználó köteles az alapadataiban bekövetkezett változásokat, a rendszerben 15 napon belül aktualizálni.
      </p>
      <p>
        5.4 A Felhasználót a szolgáltatás igénybevételéhez szükséges azonosító és jelszó tekintetében 
        harmadik személyekkel szemben titoktartási kötelezettség terheli, amelynek alapján 
        köteles azokat titokban tartani. Ezen kötelezettségének megszegéséből eredő kárért teljes körű felelősséggel tartozik.
      </p>
      <h2>6. A felhasználói szerződés megszűnése</h2>
      <p>
        6.1 A felhasználói szerződés megszűnik:
        </p>
      <ul>
        <li>a Szolgáltató jogutód nélküli megszűnésével,</li>
        <li>a Felhasználó jogutód nélküli megszűnésével,</li>
        <li>a felek közös megegyezésével,</li>
        <li>bármelyik fél által történő felmondással.</li>
      </ul>
      <p>
        A Felhasználói Szerződés megszűnésére irányuló nyilatkozatok kizárólag írásban érvényesek.
        </p>
      <p>
        6.2 A Szolgáltató azonnali hatállyal egyoldalúan felmondhatja a Felhasználói Szerződést és megszüntetheti a Feladatkiadás rendszerhez a hozzáférését, ha jelen ÁSZF-ben foglalt rendelkezéseket a Felhasználó súlyosan megszegi. Súlyos szerződésszegésnek minősül különösen:
      </p>
      <ul>
        <li>a díjfizetés 60 napos késedelme,</li>
        <li>valótlan adatok megadása,</li>
        <li>más tagok zaklatása, rágalmazása, becsületsértése vagy más módon más felhasználók személyiségi és egyéb jogainak megsértése,</li>
        <li>rendszer rendeltetésszerű működésében zavar okozása</li>
      </ul>
      <p>
        6.3 A szerződés megszűnésének visszamenőleges hatálya nincs, az nem érinti a Szolgáltatónak a 
        megkezdett szolgáltatási időszakra vonatkozó előfizetési díj-igényét, amelyért Felhasználó köteles helytállni.
      </p>
      <p>
        6.4 A Felhasználó a Felhasználói Szerződést legkésőbb az előfizetési időszak lejárta előtt 15 nappal, 
        írásban mondhatja fel. Felmondás esetén a Felhasználó a fennmaradó előfizetési időszakra előfizetés - 
        visszatérítési igénnyel nem állhat elő. Amennyiben a Felhasználó a fent említett határidőig a 
        Felhasználói Szerződést nem mondja fel, annak hatálya automatikusan a felhasználó által választott 
        előfizetési periódussal meghosszabbodik.
      </p>
      <h2>7. Az Általános Szerződési Feltételek módosításának lehetősége</h2>
      <p>
        Jelen tartalommal az Általános Szerződési Feltételek 2014. május 1-től hatályosak.
      </p>
      <p>
        Az üzemeltető fenntartja a jogot, hogy jelen Általános Szerződési Feltételeket a felhasználók előzetes 
        értesítése mellett 15 napon belül egyoldalúan módosítsa. A felhasználó elfogadja a módosított feltételeket, 
        amennyiben annak hatálybalépése után belép a rendszerbe.
      </p>
      <p>
        A szerződésben nem szabályozott kérdésekben a hatályos Ptk. az irányadó.
      </p>
    </div>    
  </Layout>

)

export default TOSPage
