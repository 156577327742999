import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/feladatkiadas.hu_logo.png"
import Menu from "./menu"
import ReactGA from 'react-ga';

ReactGA.initialize('UA-47640313-1');


const Header = ({ siteTitle }) => {
  if (typeof window !== 'undefined') {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  return (
    <header
      style={{
        background: `white`,
        marginBottom: `1.45rem`,
        borderBottom: `1px solid #dbdcde`
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
        }}
      >
        <div 
          style={{
            float: "left"
          }}
        >
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
          
        </div>
        <div 
          style={{
            float: "right"
          }}  
        >
          <Menu>
            <a href="/files/feladatkiadas.pdf" 
                  target="blank" 
                  title="Feladat kiadás, delegálás ingyenes e-book"
                  onClick={e => {
                    ReactGA.event('PDF', 'Download', 'Sikeres feladatkiadas');
                    // ga('send', 'event', 'PDF', 'Download', 'Sikeres feladatkiadas');
                  }} >
              Delegálás e-book
            </a>
            <Link to={'/bemutato'} 
                  onClick={e => {
                    ReactGA.event('SHOW', 'Functions', 'menu');
                    // ga('send', 'event', 'SHOW', 'Functions', 'menu');
                  }}
                title="Feladatkiadás, delegálás, funkciók, bemutató"
            >
              Funkciók
            </Link>
            <a  href="/app" 
                onClick={e => {
                  ReactGA.event('APP', 'Entry', 'menu');
                  // ga('send', 'event', 'APP', 'Entry', 'menu');
                }} 
                title="Belépés a feladatkiadás alkalmazásba"
            >
              Belépés
            </a>          
          </Menu>
        </div>
        <div style={{float: "none", clear: "both"}}></div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
