import React from "react"
import "./menu.css"


const Menu = ({ children }) => {

  return (
    <div className="main_menu">
      <div className="content">
        {children}  
      </div>
    </div>
  )
}

export default Menu
