import React from "react"
import "./provider.css"

const Provider = () => {

  return (
    <table className="provider" cellSpacing="0">
      <tbody>
        <tr>
          <td><b>Üzemeltető neve:</b></td>
          <td>Hicomm Üzleti Kommunikációs Kft.</td>
        </tr>
        <tr>
          <td><b>Székhely:</b></td>
          <td>7400 Kaposvár, Egyenesi út 98.</td>
        </tr>
        <tr>
          <td><b>Adószám:</b></td>
          <td>12316120-3-14</td>
        </tr>
        <tr>
          <td><b>Cégjegyzékszám:</b></td>
          <td>14-09-307358</td>
        </tr>
        <tr>
          <td><b>Nyilvántartó:</b></td>
          <td>Somogy Megyei Bíróság mint Cégbíróság</td>
        </tr>
        <tr>
          <td><b>Képviselő:</b></td>
          <td>Horváth Győző</td>
        </tr>
        <tr>
          <td><b>Elérhetőség:</b></td>
          <td>info@hicomm.hu</td>
        </tr>
      </tbody>
    </table>
  )
}

export default Provider
